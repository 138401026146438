import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'

Vue.component(FeatherIcon.name, FeatherIcon)

let mainBaseUrl  = process.env.VUE_APP_DEV;

if (process.env.NODE_ENV === 'production'){
    mainBaseUrl = process.env.VUE_APP_BUILD;
}
export default mainBaseUrl;
