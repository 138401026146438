import Vue from "vue";

// axios
import axios from "axios";
import mainBaseUrl from "@/global-components";

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: `${mainBaseUrl}`,
});

Vue.prototype.$http = axiosIns;

export default axiosIns;
